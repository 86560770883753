/*!
* Colorpicker for Bootstrap
*
* Copyright 2012 Stefan Petre
* Licensed under the Apache License v2.0
* http://www.apache.org/licenses/LICENSE-2.0
*
*/
.colorpicker-saturation {
	width: 100px;
	height: 100px;
	background-image: url(../img/saturation.png);
	cursor: crosshair;
	float: left;
}
.colorpicker-saturation i {
	display: block;
	height: 5px;
	width: 5px;
	border: 1px solid #000;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	position: absolute;
	top: 0;
	left: 0;
	margin: -4px 0 0 -4px;
}
.colorpicker-saturation i b {
	display: block;
	height: 5px;
	width: 5px;
	border: 1px solid #fff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
.colorpicker-hue, .colorpicker-alpha {
	width: 15px;
	height: 100px;
	float: left;
	cursor: row-resize;
	margin-left: 4px;
	margin-bottom: 4px;
}
.colorpicker-hue i, .colorpicker-alpha i {
	display: block;
	height: 1px;
	background: #000;
	border-top: 1px solid #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	margin-top: -1px;
}
.colorpicker-hue {
	background-image: url(../img/hue.png);
}
.colorpicker-alpha {
	background-image: url(../img/alpha.png);
	display: none;
}
.colorpicker {
	*zoom: 1;
	top: 0;
	left: 0;
	padding: 4px;
	min-width: 120px;
	margin-top: 1px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
.colorpicker:before, .colorpicker:after {
	display: table;
	content: "";
}
.colorpicker:after {
	clear: both;
}
.colorpicker:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: -7px;
	left: 6px;
}
.colorpicker:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #ffffff;
	position: absolute;
	top: -6px;
	left: 7px;
}
.colorpicker div {
	position: relative;
}
.colorpicker.alpha {
	min-width: 140px;
}
.colorpicker.alpha .colorpicker-alpha {
	display: block;
}
.colorpicker-color {
	height: 10px;
	margin-top: 5px;
	clear: both;
	background-image: url(../img/alpha.png);
	background-position: 0 100%;
}
.colorpicker-color div {
	height: 10px;
}
.input-append.color .add-on i, .input-prepend.color .add-on i {
	display: block;
	cursor: pointer;
	width: 16px;
	height: 16px;
} 